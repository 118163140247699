export function removeItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}

export function removeItemM(items, item) {
  let index = [...items].findIndex(_item => _item.material == item.material);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}

export function replaceItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1, item);
  }
  return items
}

export function insertItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index == -1) {
    items.splice(0, 0, item);
  }
  return items
}

export function insertItemM(items, item) {
  let index = [...items].findIndex(_item => _item.material == item.material);
  if (index == -1) {
    items.splice(0, 0, item);
  }
  return items
}

export function removeItemBatch(items, item) {
  let index = [...items].findIndex(_item => _item.key == item.key);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}

export function goodCategoryTree(data){
  const treeData = []
  const res = data;
  res.forEach((item) => {
    if(item.pid == 0){
      item.key = item.id
      treeData[Object.keys(treeData).length] = item
    }
  })
  res.forEach((item) => {
    if(item.pid != 0){
      for(const k in treeData){
        let obj = treeData[k]
        if(obj.key == item.pid){
          //如果相同，表明已经存入
          let l = 0
          if(Object.keys(obj).includes('children')){
            l = Object.keys(obj.children).length
          }else{
            obj.children = []
          }
          obj.children[l] = item
        }
      }
    }
  })

  return treeData
}

export default { removeItem, removeItemM,  replaceItem, insertItem, insertItemM, removeItemBatch, goodCategoryTree }
